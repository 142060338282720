import { gql } from '@apollo/client'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Button, Chip, Menu, MenuItem, TableCell, TableRow, Tooltip } from '@mui/material'
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks'
import moment from 'moment-timezone'
import Papa from 'papaparse'
import { useCallback, useMemo, useState } from 'react'
import {
  isAgaveIntegration,
  isHh2Integration,
  isSupportedIntegrationMigration,
  requiresCredential,
  supportsAgaveDebuggerLink,
  supportsCredentialsAutoRotation,
} from 'siteline-common-all'
import { colors, saveAs, useSitelineSnackbar } from 'siteline-common-web'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import * as fragments from '../../common/graphql/Fragments'
import {
  CompanyIntegrationProperties,
  useAgaveDebuggerSessionUrlLazyQuery,
  useArchiveCompanyIntegrationMutation,
  useMigrateIntegrationContractsMutation,
  useUnarchiveCompanyIntegrationMutation,
} from '../../common/graphql/apollo-operations'
import { getAgaveOrHh2Suffix } from '../../common/util/Integration'
import { AddOrUpdateCompanyIntegrationModal } from './AddOrUpdateCompanyIntegrationModal'

gql`
  mutation archiveCompanyIntegration($id: ID!) {
    archiveCompanyIntegration(id: $id) {
      ...CompanyIntegrationProperties
    }
  }
  ${fragments.companyIntegration}
`

gql`
  mutation unarchiveCompanyIntegration($id: ID!) {
    unarchiveCompanyIntegration(id: $id) {
      ...CompanyIntegrationProperties
    }
  }
  ${fragments.companyIntegration}
`

gql`
  mutation migrateIntegrationContracts($input: MigrateIntegrationContractsInput!) {
    migrateIntegrationContracts(input: $input) {
      contractsQueued {
        id
        internalProjectNumber
        project {
          id
          name
          projectNumber
        }
      }
      contractsIgnored {
        id
        internalProjectNumber
        project {
          id
          name
          projectNumber
        }
      }
    }
  }
`

type CompanyIntegrationRowProps = {
  company: fragments.DetailedCompany
  integration: CompanyIntegrationProperties
  onUpdateCredentials: () => void
  onRotateCredentials: () => void
  onExportCredentials: () => void
  onEnableAutoRotation: () => void
}

/** A single row in the list of company integrations **/
export function CompanyIntegrationRow({
  company,
  integration,
  onUpdateCredentials,
  onRotateCredentials,
  onEnableAutoRotation,
  onExportCredentials,
}: CompanyIntegrationRowProps) {
  const [archiveIntegration] = useArchiveCompanyIntegrationMutation()
  const [unarchiveIntegration] = useUnarchiveCompanyIntegrationMutation()
  const [migrateIntegrationContracts] = useMigrateIntegrationContractsMutation()
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false)
  const snackbar = useSitelineSnackbar()
  const popupState = usePopupState({ variant: 'popover', popupId: 'formActions' })

  const handleArchive = useCallback(
    (integration: CompanyIntegrationProperties) => {
      const confirmation = window.confirm(
        'Are you sure you want to disable this integration? Contracts with this integration will no longer be able to read/write to the 3rd-party.'
      )
      if (!confirmation) {
        return
      }

      archiveIntegration({
        variables: {
          id: integration.id,
        },
      })
        .then(() => snackbar.showSuccess('Disabled integration'))
        .catch((error) => snackbar.showError(error.message))
    },
    [archiveIntegration, snackbar]
  )

  const hasDebuggerLink = supportsAgaveDebuggerLink(integration.type)

  const [getDebuggerUrl] = useAgaveDebuggerSessionUrlLazyQuery()
  const openDebugger = useCallback(async () => {
    const { data } = await getDebuggerUrl({
      variables: { input: { companyIntegrationId: integration.id } },
    })
    if (data?.agaveDebuggerSessionUrl) {
      window.open(data.agaveDebuggerSessionUrl)
    }
  }, [integration, getDebuggerUrl])

  const handleUnarchive = useCallback(
    (integration: CompanyIntegrationProperties) => {
      const confirmation = window.confirm(
        'Are you sure you want to re-enable this integration? Contracts with this integration will be able to read/write to the 3rd-party again.'
      )
      if (!confirmation) {
        return
      }

      unarchiveIntegration({
        variables: {
          id: integration.id,
        },
      })
        .then(() => snackbar.showSuccess('Enabled integration'))
        .catch((error) => snackbar.showError(error.message))
    },
    [unarchiveIntegration, snackbar]
  )

  const handleMigrateProjects = useCallback(
    async (fromCompanyIntegration: CompanyIntegrationProperties) => {
      const confirmation = window.confirm(
        `Are you sure you want to do this?\n\nThis will attempt to update every project that is integrated with ${fromCompanyIntegration.longName}${getAgaveOrHh2Suffix(fromCompanyIntegration.type)}. If a matching job is found in ${integration.longName}${getAgaveOrHh2Suffix(integration.type)}, a new integration will be added and the previous integration will be removed.\n\nMake sure to update any required integration metadata on this integration (e.g. combo job preference) before migrating.`
      )
      if (!confirmation) {
        return
      }
      snackbar.showLoading('Finding projects to migrate...')
      try {
        const { data } = await migrateIntegrationContracts({
          variables: {
            input: {
              fromCompanyIntegrationId: fromCompanyIntegration.id,
              toCompanyIntegrationId: integration.id,
            },
          },
        })
        const migrationSummary = data?.migrateIntegrationContracts
        const numContractsQueued = migrationSummary?.contractsQueued.length ?? 0
        const numContractsTotal =
          numContractsQueued + (migrationSummary?.contractsIgnored.length ?? 0)
        snackbar.showSuccess(
          `Queued migration of ${numContractsQueued} of ${numContractsTotal} projects. You'll receive an email when the import is complete.`
        )

        if (data) {
          const summary = []
          if (data.migrateIntegrationContracts.contractsQueued.length > 0) {
            summary.push(['Projects queued for migration'])
            summary.push(
              ...data.migrateIntegrationContracts.contractsQueued.map((contract) => [
                contract.internalProjectNumber ?? contract.project.projectNumber,
                contract.project.name,
              ])
            )
          }
          if (data.migrateIntegrationContracts.contractsIgnored.length > 0) {
            summary.push(['Projects ignored (no match or other error)'])
            summary.push(
              ...data.migrateIntegrationContracts.contractsIgnored.map((contract) => [
                contract.internalProjectNumber ?? contract.project.projectNumber,
                contract.project.name,
              ])
            )
          }
          if (summary.length > 0) {
            const csv = Papa.unparse(summary, {
              delimiter: ',',
              quotes: true,
            })
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
            saveAs(blob, 'migration-summary.csv')
          }
        }
      } catch (err) {
        snackbar.showError(err.message)
      }
    },
    [integration, migrateIntegrationContracts, snackbar]
  )

  let statusText: string
  let statusColor: string
  let tooltipTitle: string

  const canUpdateLabel = useMemo(() => !integration.archivedAt, [integration.archivedAt])
  const canUpdateCredentials = useMemo(() => !integration.archivedAt, [integration])
  const canEnableAutoRotation = useMemo(
    () =>
      !integration.archivedAt &&
      !integration.credentialsReadyForAutoRotation &&
      supportsCredentialsAutoRotation(integration.type),
    [integration]
  )
  const canRotateCredentials = useMemo(
    () =>
      !integration.archivedAt &&
      integration.credentialsReadyForAutoRotation &&
      supportsCredentialsAutoRotation(integration.type),
    [integration]
  )
  const canExportCredentialsToPostman = useMemo(
    () =>
      !integration.archivedAt &&
      integration.credentialsUpdatedAt &&
      (isAgaveIntegration(integration.type) || isHh2Integration(integration.type)),
    [integration]
  )
  const canDisable = useMemo(() => !integration.archivedAt, [integration.archivedAt])
  const canEnable = useMemo(() => integration.archivedAt, [integration.archivedAt])

  // A list of possible migrations from other active company integrations to this integration
  const migrations = useMemo(() => {
    if (integration.archivedAt) {
      return []
    }
    return company.companyIntegrations.filter(
      (companyIntegration) =>
        !companyIntegration.archivedAt &&
        isSupportedIntegrationMigration({
          fromType: companyIntegration.type,
          toType: integration.type,
        })
    )
  }, [company.companyIntegrations, integration])

  // Integration disabled
  if (integration.archivedAt) {
    statusText = 'Disabled'
    statusColor = colors.grey30
    tooltipTitle = `Disabled on ${moment.utc(integration.archivedAt).format('MMM DD, YYYY')}`

    // Integration enabled, but missing credentials
  } else if (!integration.credentialsUpdatedAt && requiresCredential(integration.type)) {
    statusText = 'Credentials not set'
    statusColor = colors.red30
    tooltipTitle = 'Credentials were never set for this integration. All imports/exports will fail.'

    // Credentials set, but not ready for auto-rotation (Textura credentials not linked to 1Password)
  } else if (
    integration.credentialsUpdatedAt &&
    supportsCredentialsAutoRotation(integration.type) &&
    !integration.credentialsReadyForAutoRotation
  ) {
    statusText = 'Missing auto-rotation settings'
    statusColor = colors.orange30
    tooltipTitle = `Credentials are not linked to 1Password and cannot be automatically rotated`

    // Integration enabled and credentials set
  } else if (integration.credentialsUpdatedAt) {
    statusText = 'Enabled'
    statusColor = colors.green30
    tooltipTitle = `Credentials updated on ${moment
      .utc(integration.credentialsUpdatedAt)
      .format('MMM DD, YYYY')}`

    // Integration enabled and credentials not set (but they're not required)
  } else {
    statusText = 'Enabled'
    statusColor = colors.green30
    tooltipTitle = `Enabled on ${moment.utc(integration.createdAt).format('MMM DD, YYYY')}`
  }

  return (
    <>
      <TableRow key={integration.id}>
        <TableCell>
          {integration.longName}
          {getAgaveOrHh2Suffix(integration.type)}
        </TableCell>
        <TableCell>
          <Tooltip title={tooltipTitle} placement="top">
            <Chip label={statusText} sx={{ backgroundColor: statusColor }} size="small"></Chip>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            variant="outlined"
            endIcon={<ArrowDropDownIcon />}
            {...bindToggle(popupState)}
          >
            Actions
          </Button>
          <Menu {...bindMenu(popupState)}>
            {canUpdateLabel && (
              <MenuItem onClick={() => setIsUpdateModalOpen(true)}>
                Update integration metadata
              </MenuItem>
            )}
            {canUpdateCredentials && (
              <MenuItem onClick={() => onUpdateCredentials()}>
                Set credentials
                {supportsCredentialsAutoRotation(integration.type) ? ' manually' : ''}
              </MenuItem>
            )}
            {canEnableAutoRotation && (
              <MenuItem onClick={() => onEnableAutoRotation()}>
                Enable credentials auto-rotation
              </MenuItem>
            )}
            {canRotateCredentials && (
              <MenuItem onClick={() => onRotateCredentials()}>Rotate credentials</MenuItem>
            )}
            {canExportCredentialsToPostman && (
              <MenuItem onClick={() => onExportCredentials()}>
                Export credentials to Postman
              </MenuItem>
            )}
            {hasDebuggerLink && (
              <MenuItem onClick={() => openDebugger()}>Open Agave debugger</MenuItem>
            )}
            {canDisable && (
              <MenuItem onClick={() => handleArchive(integration)} style={{ color: '#ff0000' }}>
                Disable integration
              </MenuItem>
            )}
            {canEnable && (
              <MenuItem onClick={() => handleUnarchive(integration)}>
                Re-enable integration
              </MenuItem>
            )}
            {migrations.map((fromIntegration) => (
              <MenuItem
                key={fromIntegration.id}
                onClick={() => handleMigrateProjects(fromIntegration)}
              >
                Migrate projects from {fromIntegration.longName}
                {getAgaveOrHh2Suffix(fromIntegration.type)}
              </MenuItem>
            ))}
          </Menu>
          <IdentifierIconButton id={integration.id} />
        </TableCell>
      </TableRow>
      <AddOrUpdateCompanyIntegrationModal
        open={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        company={company}
        companyIntegration={integration}
      />
    </>
  )
}
