import type { EmptyObject } from 'type-fest'
import { z } from 'zod'
import {
  IntegrationProjectsFilterField,
  Sage100InvoiceTaxType,
  type IntegrationSyncResultCode,
} from '../enums.js'
import {
  MAX_FOUNDATION_INVOICE_NUMBER_LENGTH,
  MAX_SAGE_300_DRAW_NUMBER_LENGTH,
  MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH,
  MAX_SAGE_INVOICE_CODE_LENGTH,
  MAX_SPECTRUM_BATCH_NUMBER_LENGTH,
  MAX_SPECTRUM_INVOICE_CODE_LENGTH,
  MAX_VISTA_BATCH_NUMBER_LENGTH,
  MAX_VISTA_INVOICE_NUMBER_LENGTH,
} from '../utils/integration-sync.js'
import {
  zodDaySchema,
  zodIntegrationEntityIdSchema,
  zodUuidSchema,
} from '../utils/validation-zod.js'

export type IntegrationMappingsTextura = {
  type: 'textura'
  project: {
    texturaProjectId?: string
    name?: string
  }
  contract: {
    texturaContractId?: string
  }
  legalDocumentRequirements: {
    sitelineLegalRequirementId: string
    texturaLegalDocumentRequirementId: string
    isRecurring: boolean
  }[]
  vendorContracts: {
    sitelineVendorId: string
    texturaVendorContractId: string
  }[]
}
export type IntegrationMappingsGcPay = {
  type: 'gcPay'
  project: {
    gcPayProjectId?: string
    name?: string
  }
  contract: {
    gcPaySovId?: string
  }
}

export type IntegrationMappingsAcumatica = {
  type: 'acumatica'
  project: {
    agaveProjectId?: string
    name?: string
  }
  contract: {
    agaveContractId?: string
  }
  customer?: {
    agaveCustomerId: string
    name: string
  }
}

export type IntegrationMappingsSage100Contractor = {
  type: 'sage100Contractor'
  project: {
    hh2ProjectId?: string
    name?: string
  }
  contract: {
    hh2ContractId?: string
  }
  incomeAccount?: {
    hh2AccountId: string
  }
  defaultInvoiceTaxType?: Sage100InvoiceTaxType
  defaultIntegrationTaxAccountId?: string

  // Default list of line items that represent tax amounts, to handle
  // the case of a post-tax SOV where taxes are explicitly inserted
  // as separate line items.
  defaultSitelineTaxLineItemIds?: string[]
}

export type IntegrationMappingsSage100ContractorAgave = {
  type: 'sage100ContractorAgave'
  project: {
    agaveProjectId?: string
    name?: string
  }
  contract: {
    agaveContractId?: string
  }
  customer?: {
    agaveCustomerId: string
    name: string
  }
  incomeAccount?: {
    agaveAccountId: string
    subsidiaryAccountRef?: string
  }
  defaultInvoiceTaxType?: Sage100InvoiceTaxType
  defaultIntegrationTaxAccountId?: string
  defaultTaxSubsidiaryAccountRef?: string

  // Default list of line items that represent tax amounts, to handle
  // the case of a post-tax SOV where taxes are explicitly inserted
  // as separate line items.
  defaultSitelineTaxLineItemIds?: string[]
}

export type IntegrationMappingsSage300Cre = {
  type: 'sage300Cre'
  project: {
    hh2ProjectId?: string
    name?: string
  }
  contract: {
    hh2ContractId?: string
  }
  taxGroup?: {
    hh2TaxGroupId: string
    name: string
    taxPercent: number
  }
  customer?: {
    hh2CustomerId: string
    name: string
  }
}
export type IntegrationMappingsSage300CreAgave = {
  type: 'sage300CreAgave'
  project: {
    agaveProjectId?: string
    name?: string
  }
  contract: {
    agaveContractId?: string
  }
  taxGroup?: {
    agaveTaxGroupId: string
    name: string
    taxPercent: number
  }
  customer?: {
    agaveCustomerId: string
    name: string
  }
}
export type IntegrationMappingsViewpointSpectrum = {
  type: 'spectrum'
  project: {
    agaveProjectId?: string
    name?: string
  }
  contract: {
    agaveContractId?: string
  }
  customer?: {
    agaveCustomerId: string
    name: string
  }
  taxGroup?: {
    agaveTaxGroupId: string
    name: string
    code: string
    taxPercent: number
  }
}
export type IntegrationMappingsViewpointVista = {
  type: 'vista'
  project: {
    agaveProjectId?: string
    name?: string
  }
  contract: {
    agaveContractId?: string
  }
  customer?: {
    agaveCustomerId: string
    name: string
  }
  taxGroup?: {
    agaveTaxGroupId: string
    name: string
    code: string
    taxPercent: number
  }
}

export type IntegrationMappingsProcore = {
  type: 'procore'
  associatedCompanyId?: string
  project: {
    agaveProjectId?: string
    name?: string
  }
  contract: {
    agaveContractId?: string
  }
}

export type FoundationLineItemMapping = {
  sitelineLineItemId: string
  agaveLedgerAccountId: string
}

export type IntegrationMappingsFoundation = {
  type: 'foundation'
  project: {
    agaveProjectId?: string
    name?: string
  }
  contract: {
    agaveContractId?: string
  }
  customer?: {
    agaveCustomerId: string
    name: string
  }
  // One of ledgerAccount or lineItemMappings must be defined
  // Mappings if the customer uses just one default ledger account and income type
  ledgerAccount?: {
    agaveAccountId: string
  }

  // Mappings if the customer assigns a different ledger account or income type to each line
  lineItemMappings?: FoundationLineItemMapping[]

  retentionLedgerAccount?: {
    agaveSourceId: string
  }
  // Agave does not return uuids for income types, so we store the income type id in the mappings
  // directly
  incomeTypeId?: string
}

export type IntegrationMappingsSageIntacct = {
  type: 'sageIntacct'
  project: {
    agaveProjectId?: string
    name?: string
  }
  contract: {
    agaveContractId?: string
  }
  customer?: {
    agaveCustomerId: string
    name: string
  }
}

export type IntegrationMappingsTest = {
  type: 'test'
}
export type IntegrationMappingsFile = {
  type: 'file'
}
export type IntegrationMappings =
  | IntegrationMappingsAcumatica
  | IntegrationMappingsTextura
  | IntegrationMappingsGcPay
  | IntegrationMappingsProcore
  | IntegrationMappingsFoundation
  | IntegrationMappingsSageIntacct
  | IntegrationMappingsSage100Contractor
  | IntegrationMappingsSage100ContractorAgave
  | IntegrationMappingsSage300Cre
  | IntegrationMappingsSage300CreAgave
  | IntegrationMappingsViewpointSpectrum
  | IntegrationMappingsViewpointVista
  | IntegrationMappingsTest
  | IntegrationMappingsFile

export type VendorIntegrationMappings = {
  integrations?: {
    companyIntegrationId: string
    integrationVendorId: string
  }[]

  // Deprecated. These mappings are preserved for legacy reasons but are not maintained and should
  // not be used or relied on.
  hh2?: {
    vendorId?: string
  }
  agave?: {
    vendorId?: string
  }
}

export type GeneralContractorMappingComputerEase = {
  type: 'computerEase'
  customerId: string
}

export type GeneralContractorMappingTest = {
  type: 'test'
}

export type GeneralContractorMapping =
  | GeneralContractorMappingComputerEase
  | GeneralContractorMappingTest

export type GeneralContractorMappings = {
  companyId: string
  mappings: GeneralContractorMapping[]
}

export type CompanyIntegrationMappings = {
  /** Integration mappings related to the company's GCs */
  generalContractorMappings?: GeneralContractorMappings[]
}

export const writeSyncPayloadPayAppTexturaSchema = z.object({
  type: z.literal('payAppTextura'),
  payAppId: zodUuidSchema,
})
export type WriteSyncPayloadPayAppTextura = z.infer<typeof writeSyncPayloadPayAppTexturaSchema>

export const writeSyncPayloadPayAppGcPaySchema = z.object({
  type: z.literal('payAppGcPay'),
  payAppId: zodUuidSchema,
})
export type WriteSyncPayloadPayAppGcPay = z.infer<typeof writeSyncPayloadPayAppGcPaySchema>

export const writeSyncPayloadPayAppProcoreSchema = z.object({
  type: z.literal('payAppProcore'),
  payAppId: zodUuidSchema,
})
export type WriteSyncPayloadPayAppProcore = z.infer<typeof writeSyncPayloadPayAppProcoreSchema>

export const writeSyncPayloadPayAppSage100Schema = z.object({
  type: z.literal('payAppSage100'),
  payAppId: zodUuidSchema,
  invoiceCode: z.string().min(1).max(MAX_SAGE_INVOICE_CODE_LENGTH),
  invoiceDate: zodDaySchema,
  dueDate: zodDaySchema,
  /**
   * For the hh2-backed integration, this is the subsidiary account ID if using a subsidiary
   * account. If the account has no subsidiary, this is the parent account ID.
   * For the Agave-backed integration, this is always the parent acconut ID.
   */
  integrationAccountId: zodIntegrationEntityIdSchema,
  /**
   * Used only for Agave-backed integration if using a subsidiary account. This is the recnum of the
   * subsidiary account, not a UUID.
   */
  subsidiaryAccountRef: z.string().min(1).optional(),

  // How to handle taxes on the invoice
  invoiceTaxType: z.nativeEnum(Sage100InvoiceTaxType).optional(),

  // Only allowed if tax type is INVOICE_DISTRIBUTION or SITELINE_SOV_POST_TAX.
  // If not provided, taxes will go to the default account.
  integrationTaxAccountId: zodIntegrationEntityIdSchema.optional(),

  /** See `subsidiaryAccountRef` above; used only for Agave-backed integration */
  taxSubsidiaryAccountRef: z.string().min(1).optional(),

  // Only allowed if tax type is SITELINE_SOV_POST_TAX
  // If provided, tax will be calculated from line items in the SOV (which is post-tax),
  // as opposed to using Siteline tax groups.
  // This is to handle the case of a post-tax SOV where taxes are explicitly inserted
  // as separate line items.
  sitelineTaxLineItemIds: z.array(zodUuidSchema).optional(),
})
export type WriteSyncPayloadPayAppSage100 = z.infer<typeof writeSyncPayloadPayAppSage100Schema>

export const writeSyncPayloadPayAppFoundationSchema = z
  .object({
    type: z.literal('payAppFoundation'),
    payAppId: zodUuidSchema,
    invoiceDate: zodDaySchema,
    dueDate: zodDaySchema,

    // The customer can enter an invoice code manually
    // or leave the field blank to allow Foundation to generate the code
    invoiceNumber: z.string().min(1).max(MAX_FOUNDATION_INVOICE_NUMBER_LENGTH).optional(),

    // We use Foundation source account code for retention
    // this refers to a ledger account in which retention is held
    // see https://sitelinetech.slack.com/archives/C03RTRXJYCV/p1715704734297519?thread_ts=1712187177.353769&cid=C03RTRXJYCV
    integrationLedgerAccountCodeForRetention: z.string().optional(),

    // Mappings if the customer assigns a different ledger account or income type to each line
    lineItemMappings: z
      .array(
        z.object({
          sitelineLineItemId: zodUuidSchema,
          agaveLedgerAccountId: zodUuidSchema,
        })
      )
      .optional(),

    // Mappings if the customer uses just one default ledger account and income type
    integrationLedgerAccountId: zodIntegrationEntityIdSchema.optional(),

    // Income type is required if the customer has income types enabled; otherwise, we pass null
    incomeTypeId: z.string().min(1).nullable(),
  })
  .refine(
    (data) =>
      (data.lineItemMappings && !data.integrationLedgerAccountId) ||
      (!data.lineItemMappings && data.integrationLedgerAccountId),
    {
      message: 'Only one of lineItemMappings or integrationLedgerAccountId must be provided',
    }
  )
export type WriteSyncPayloadPayAppFoundation = z.infer<
  typeof writeSyncPayloadPayAppFoundationSchema
>

// Payload used when marking a pay app as synced manually.
// This payload is never processed by the integrations service, it's essentially just a fake row
// inserted in the database to keep track of manual syncs.
export const writeSyncPayloadPayAppManualSchema = z.object({
  type: z.literal('payAppManual'),
  payAppId: zodUuidSchema,
})
export type WriteSyncPayloadPayAppManual = z.infer<typeof writeSyncPayloadPayAppManualSchema>

export const writeSyncPayloadPayAppQuickbooksSchema = z.object({
  type: z.literal('payAppQuickbooks'),
  payAppId: zodUuidSchema,

  // The following fields are not provided when bulk exporting
  invoiceNumber: z.string().optional(),
  projectName: z.string().optional(),
  customerName: z.string().optional(),
  includeRetention: z.boolean().optional(),
  combineAsSingleLine: z.boolean().optional(),
  singleLineDescription: z.string().optional(),
})
export type WriteSyncPayloadPayAppQuickbooks = z.infer<
  typeof writeSyncPayloadPayAppQuickbooksSchema
>

export const writeSyncPayloadPayAppFoundationFileGenieSchema = z.object({
  type: z.literal('payAppFoundationFileGenie'),
  payAppId: zodUuidSchema,
})
export type WriteSyncPayloadPayAppFoundationFileGenie = z.infer<
  typeof writeSyncPayloadPayAppFoundationFileGenieSchema
>

export const writeSyncPayloadPayAppFoundationFileFsiSchema = z.object({
  type: z.literal('payAppFoundationFileFsi'),
  payAppId: zodUuidSchema,
  customerNumber: z.string(),
  projectNumber: z.string(),
})
export type WriteSyncPayloadPayAppFoundationFileFsi = z.infer<
  typeof writeSyncPayloadPayAppFoundationFileFsiSchema
>

export const writeSyncPayloadPayAppComputerEaseSchema = z.object({
  type: z.literal('payAppComputerEase'),
  payAppId: zodUuidSchema,
  customerId: z.string().optional(),
  jobId: z.string().optional(),
  arAccount: z.string().optional(),
  salesAccount: z.string().optional(),
})
export type WriteSyncPayloadPayAppComputerEase = z.infer<
  typeof writeSyncPayloadPayAppComputerEaseSchema
>

export type WriteSyncPayloadPayApp =
  | WriteSyncPayloadPayAppTextura
  | WriteSyncPayloadPayAppGcPay
  | WriteSyncPayloadPayAppProcore
  | WriteSyncPayloadPayAppSage100
  | WriteSyncPayloadPayAppFoundation
  | WriteSyncPayloadPayAppQuickbooks
  | WriteSyncPayloadPayAppFoundationFileGenie
  | WriteSyncPayloadPayAppFoundationFileFsi
  | WriteSyncPayloadPayAppComputerEase
  | WriteSyncPayloadPayAppManual

export const writeSyncPayloadPayAppLineItemsSage300Schema = z.object({
  type: z.literal('payAppLineItemsSage300'),
  payAppId: zodUuidSchema,
  payAppLineItems: z
    .array(
      z.object({
        integrationLineItemId: z.string(),
        progressBilled: z.number(),
        retention: z.number(),
        sitelineTaxGroupId: z.string().optional().nullable(),
      })
    )
    .min(0), // T&M pay apps don't require allocation
  invoiceDate: zodDaySchema,
  invoiceCode: z.string().min(1),
  dueDate: zodDaySchema,
  draw: z.string().min(1).max(MAX_SAGE_300_DRAW_NUMBER_LENGTH),
})
export type WriteSyncPayloadPayAppLineItemsSage300 = z.infer<
  typeof writeSyncPayloadPayAppLineItemsSage300Schema
>

export const writeSyncPayloadPayAppLineItemsSpectrumSchema = z.object({
  type: z.literal('payAppLineItemsSpectrum'),
  payAppId: zodUuidSchema,
  payAppLineItems: z
    .array(
      z.object({
        integrationLineItemId: z.string(),
        progressBilled: z.number(),
        retention: z.number(),
        sitelineTaxGroupId: z.string().optional().nullable(),
      })
    )
    .min(0), // T&M pay apps don't require allocation
  invoiceDate: zodDaySchema,
  invoiceCode: z.string().min(1).max(MAX_SPECTRUM_INVOICE_CODE_LENGTH),
  batchNumber: z.string().min(1).max(MAX_SPECTRUM_BATCH_NUMBER_LENGTH),
})
export type WriteSyncPayloadPayAppLineItemsSpectrum = z.infer<
  typeof writeSyncPayloadPayAppLineItemsSpectrumSchema
>

export const writeSyncPayloadPayAppLineItemsVistaSchema = z.object({
  type: z.literal('payAppLineItemsVista'),
  payAppId: zodUuidSchema,
  payAppLineItems: z
    .array(
      z.object({
        integrationLineItemId: z.string(),
        progressBilled: z.number(),
        retention: z.number(),
        sitelineTaxGroupId: z.string().optional().nullable(),
      })
    )
    .min(0), // T&M pay apps don't require allocation
  invoiceDate: zodDaySchema,
  invoiceCode: z.string().min(1).max(MAX_VISTA_INVOICE_NUMBER_LENGTH).optional(),
  batchNumber: z.string().min(1).max(MAX_VISTA_BATCH_NUMBER_LENGTH).optional(),
})
export type WriteSyncPayloadPayAppLineItemsVista = z.infer<
  typeof writeSyncPayloadPayAppLineItemsVistaSchema
>

export const writeSyncPayloadPayAppLineItemsAcumaticaSchema = z.object({
  type: z.literal('payAppLineItemsAcumatica'),
  payAppId: zodUuidSchema,
  payAppLineItems: z
    .array(
      z.object({
        integrationLineItemId: z.string(),
        progressBilled: z.number(),
        retention: z.number(),
        sitelineTaxGroupId: z.string().optional().nullable(),
      })
    )
    .min(0), // T&M pay apps don't require allocation
  invoiceDate: zodDaySchema,
})
export type WriteSyncPayloadPayAppLineItemsAcumatica = z.infer<
  typeof writeSyncPayloadPayAppLineItemsAcumaticaSchema
>

export const writeSyncPayloadPayAppLineItemsSageIntacctSchema = z.object({
  type: z.literal('payAppLineItemsSageIntacct'),
  payAppId: zodUuidSchema,
  payAppLineItems: z
    .array(
      z.object({
        integrationLineItemId: z.string(),
        progressBilled: z.number(),
        retention: z.number(),
        retentionReleased: z.number(),
        sitelineTaxGroupId: z.string().optional().nullable(),
      })
    )
    .min(0), // T&M pay apps don't require allocation
  invoiceDate: zodDaySchema,
  invoiceCode: z.string().min(1).optional(),
  retentionInvoiceCode: z.string().min(1).optional(),
  dueDate: zodDaySchema,
  referenceNumber: z.string().min(1).max(MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH).optional(),
})
export type WriteSyncPayloadPayAppLineItemsSageIntacct = z.infer<
  typeof writeSyncPayloadPayAppLineItemsSageIntacctSchema
>

export type WriteSyncPayloadPayAppLineItems =
  | WriteSyncPayloadPayAppLineItemsSage300
  | WriteSyncPayloadPayAppLineItemsSpectrum
  | WriteSyncPayloadPayAppLineItemsVista
  | WriteSyncPayloadPayAppLineItemsAcumatica
  | WriteSyncPayloadPayAppLineItemsSageIntacct

export const writeSyncPayloadLegalRequirementSchema = z.object({
  type: z.literal('legalRequirement'),
  legalRequirementId: zodUuidSchema,
  legalDocumentIds: z.array(zodUuidSchema),
})
export type WriteSyncPayloadLegalRequirement = z.infer<
  typeof writeSyncPayloadLegalRequirementSchema
>

export const writeSyncPayloadLienWaiversSchema = z.object({
  type: z.literal('lienWaivers'),
  lienWaiverIds: z.array(zodUuidSchema),
})
export type WriteSyncPayloadLienWaivers = z.infer<typeof writeSyncPayloadLienWaiversSchema>

export type WriteSyncPayload =
  | WriteSyncPayloadPayApp
  | WriteSyncPayloadPayAppLineItems
  | WriteSyncPayloadLegalRequirement
  | WriteSyncPayloadLienWaivers

export type WriteSyncPayloadFile =
  | WriteSyncPayloadPayAppQuickbooks
  | WriteSyncPayloadPayAppFoundationFileGenie
  | WriteSyncPayloadPayAppComputerEase
  | WriteSyncPayloadPayAppFoundationFileFsi

// Deprecated – Textura / GCPay / Procore
export const deprecatedWriteSyncPayloadPayAppGenericSchema = z.object({
  type: z.literal('payApp'),
  payAppId: zodUuidSchema,
})
export type DeprecatedWriteSyncPayloadPayAppGeneric = z.infer<
  typeof deprecatedWriteSyncPayloadPayAppGenericSchema
>

// Deprecated – Sage 100
export const deprecatedWriteSyncPayloadPayAppSage100Schema = z.object({
  type: z.literal('payApp'),
  payAppId: zodUuidSchema,
  invoiceCode: z.string().min(1).max(MAX_SAGE_INVOICE_CODE_LENGTH),
  invoiceDate: zodDaySchema,
  dueDate: zodDaySchema,
  integrationAccountId: zodIntegrationEntityIdSchema,
  invoiceTaxType: z.nativeEnum(Sage100InvoiceTaxType).optional(),
  integrationTaxAccountId: zodIntegrationEntityIdSchema,
  sitelineTaxLineItemIds: z.array(zodUuidSchema).optional(),
})
export type DeprecatedWriteSyncPayloadPayAppSage100 = z.infer<
  typeof deprecatedWriteSyncPayloadPayAppSage100Schema
>

// Deprecated – Foundation
export const deprecatedWriteSyncPayloadPayAppFoundationSchema = z.object({
  type: z.literal('payApp'),
  integrationType: z.literal('Foundation'),
  payAppId: zodUuidSchema,
  invoiceDate: zodDaySchema,
  invoiceNumber: z.string().min(1).max(MAX_FOUNDATION_INVOICE_NUMBER_LENGTH).optional(),
  dueDate: zodDaySchema,
  integrationLedgerAccountCodeForRetention: z.string().optional(),
  lineItemMappings: z
    .array(
      z.object({
        sitelineLineItemId: zodUuidSchema,
        agaveLedgerAccountId: zodUuidSchema,
      })
    )
    .optional(),
  integrationLedgerAccountId: zodIntegrationEntityIdSchema.optional(),
  incomeTypeId: z.string().min(1).nullable(),
})
export type DeprecatedWriteSyncPayloadPayAppFoundation = z.infer<
  typeof deprecatedWriteSyncPayloadPayAppFoundationSchema
>

// Deprecated – Sage 300 / Vista / Spectrum / Acumatica / Sage Intacct syncs
export const deprecatedWriteSyncPayloadPayAppLineItemsSchema = z.object({
  type: z.literal('payAppLineItems'),
  payAppId: zodUuidSchema,
  payAppLineItems: z
    .array(
      z.object({
        integrationLineItemId: z.string(),
        progressBilled: z.number(),
        retention: z.number(),
        sitelineTaxGroupId: zodUuidSchema.optional().nullable(),
      })
    )
    .min(0), // T&M pay apps don't require allocation
  invoiceDate: zodDaySchema,
  // Due date required for Sage Intacct
  // Error thrown elsewhere if it's not included
  dueDate: zodDaySchema.optional(),
  invoiceCode: z.string().min(1).optional(),
  draw: z.string().min(1).max(MAX_SAGE_300_DRAW_NUMBER_LENGTH).optional(),
  batchNumber: z.string().min(1).max(MAX_SPECTRUM_BATCH_NUMBER_LENGTH).optional(),
})
export type DeprecatedWriteSyncPayloadPayAppLineItems = z.infer<
  typeof deprecatedWriteSyncPayloadPayAppLineItemsSchema
>

export type IntegrationProjectsFilterInput = {
  field: IntegrationProjectsFilterField
  values: string[] | null
}

export type WriteSyncResultSuccess = {
  type: 'success'
  message?: string
  warning?: string
  url?: string
  texturaDrawId?: string
  texturaDrawNumber?: number
}

export type WriteSyncResultFailure = {
  type: 'failure'
  error: string
  code: IntegrationSyncResultCode
}

export type WriteSyncResultQueuedInHh2 = {
  type: 'queuedInHh2'
  operationId: string
  entityId: string
  entityDescription: string
}

export type WriteSyncResult =
  | WriteSyncResultSuccess
  | WriteSyncResultFailure
  | WriteSyncResultQueuedInHh2

export type FoundationIntegrationMetadata = {
  customerNumber?: string
}

export type ComputerEaseContractMetadata = {
  customerId?: string
  jobId?: string
  arAccount?: string
  salesAccount?: string
}

export type QuickbooksIntegrationMetadata = {
  projectName: string
  customerName: string
  includeRetention: boolean
  combineAsSingleLine: boolean
  singleLineDescription?: string

  /** Overrides for the default global company integration settings */
  accountsReceivableAccount?: string
  retentionAccountsReceivableAccount?: string
  progressItemName?: string
  progressItemIncomeAccount?: string
  retentionItemName?: string
  retentionItemIncomeAccount?: string
}

export type IntegrationMetadata =
  | ComputerEaseContractMetadata
  | FoundationIntegrationMetadata
  | QuickbooksIntegrationMetadata
  | EmptyObject

export type TaxGroupIntegrationMappingsIntegration = {
  companyIntegrationId: string
  integrationTaxGroupId: string // Also called "tax code" in Agave
  name: string
}

export type TaxGroupIntegrationMappings = {
  integrations: TaxGroupIntegrationMappingsIntegration[]
}

export const getIntegrationProjectsPayloadSchema = z.object({
  filters: z
    .array(
      z.object({
        field: z.nativeEnum(IntegrationProjectsFilterField),
        values: z.array(z.string()).nullable(),
      })
    )
    .min(0)
    .optional(),
})
export type GetIntegrationProjectsPayload = z.infer<typeof getIntegrationProjectsPayloadSchema>
