import { IntegrationType } from '../enums.js'

export type IntegrationMigration = { fromType: IntegrationType; toType: IntegrationType }

// We support migrating contracts between these integrations. These are the only migrations we've
// tested the migration flow with; this will likely work for other integrations, but we should add
// them explicitly as needed.
export const SUPPORTED_INTEGRATION_MIGRATIONS = [
  {
    fromType: IntegrationType.SAGE_100_CONTRACTOR,
    toType: IntegrationType.SAGE_100_CONTRACTOR_AGAVE,
  },
  {
    fromType: IntegrationType.SAGE_100_CONTRACTOR_AGAVE,
    toType: IntegrationType.SAGE_100_CONTRACTOR,
  },
  {
    fromType: IntegrationType.SAGE_300_CRE,
    toType: IntegrationType.SAGE_300_CRE_AGAVE,
  },
  {
    fromType: IntegrationType.SAGE_300_CRE_AGAVE,
    toType: IntegrationType.SAGE_300_CRE,
  },
  {
    fromType: IntegrationType.SAGE_INTACCT,
    toType: IntegrationType.SAGE_300_CRE,
  },
  {
    fromType: IntegrationType.SAGE_300_CRE,
    toType: IntegrationType.SAGE_INTACCT,
  },
] as const

type SupportedIntegrationMigration = (typeof SUPPORTED_INTEGRATION_MIGRATIONS)[number]

export function isSupportedIntegrationMigration(
  migration: IntegrationMigration
): migration is SupportedIntegrationMigration {
  return SUPPORTED_INTEGRATION_MIGRATIONS.some(
    (supportedMigration) =>
      supportedMigration.fromType === migration.fromType &&
      supportedMigration.toType === migration.toType
  )
}
